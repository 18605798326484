import React from "react";
import { useState, useEffect } from "react";
import {TopBar} from './Common'

export default function SignUp() {
  return (
    <div className="avenir near-black">

      <div className="ph3 ph5-ns pv4 flex min-vh-100">
          <div className="pa2 w-50">
            <h1 className="f2 lh-copy">Claim <span className="blue">bestbuy.com</span> &amp; Start your free 14-day trial</h1>
            <form className="near-black mt4">
              <div className="measure">
                <div className="">
                  <label htmlFor="website" className="f6 b db mb2">Website</label>
                  <input id="website" className="input-reset ba b--transparent pa2 mb2 db w-100 bg-near-white" type="text" aria-describedby="website-desc" disabled value="bestbuy.com" />
                </div>

                <div className="mt3">
                  <label htmlFor="email" className="f6 b db mb2">Business Email{/*<span className="normal black-60">(business)</span>*/}</label>
                  <input id="email" className="input-reset ba b--black-20 pa2 mb2 db w-100" type="email" aria-describedby="email-desc" placeholder="hello@bestbuy.com" />
                  <small id="email-desc" className="f6 black-60 db mb2">We suggest using the <strong>email address you use at work</strong>.</small>
                </div>

                <div className="mt3">
                  <button className="bg-green white bn pa2 br2 f6 fw6 w-100 b--dark-blue">Sign Up</button>
                </div>
              </div>
            </form>
          </div>

          <div className="pa1 w-50 bg-lightest-blue br2">


{/*            <ul className="list pl0 ma0 lh-copy f6">
              <li className="">
                <strong></strong>
              </li>
            </ul>
*/}          </div>
      </div>
    </div>
  )
}