import React from "react";
import { useState, useEffect } from "react";
import {TopBar} from './Common'

type RatingValue = 1 | 2 | 3 | 4 | 5


function getLabel(value: RatingValue): string {
  switch (value) {
    case 1:
      return 'Bad';
    case 2:
      return 'Poor';
    case 3:
      return 'Average';
    case 4:
      return 'Great';
    case 5:
      return 'Excellent';
  }
}

function getDescription(value: RatingValue): string {
  switch (value) {
    case 1:
      return '1 star: Bad';
    case 2:
      return '2 stars: Poor';
    case 3:
      return '3 stars: Average';
    case 4:
      return '4 stars: Great';
    case 5:
      return '5 stars: Excellent';
  }
}

type RatingProps = {
  value: null | RatingValue,
  onChange: (value: RatingValue) => void,
}

function Rating({value, onChange}: RatingProps) {
  const [draftValue, onChangeDraftValue] = useState<null | RatingValue>(null);

  const commonProps = {value, draftValue, onChange, onChangeDraftValue}
  const activeValue = draftValue ?? value;

  return (
    <div
      onMouseLeave={() => {
        onChangeDraftValue(null);
      }}
    >
      <Star type={1} {...commonProps} />
      <Star type={2} {...commonProps} />
      <Star type={3} {...commonProps} />
      <Star type={4} {...commonProps} />
      <Star type={5} {...commonProps} />
    </div>
  );
}

type StarProps = {
  type: RatingValue;
  value: null | RatingValue;
  draftValue: null | RatingValue;
  onChange: (value: RatingValue) => void;
  onChangeDraftValue: (value: null | RatingValue) => void;
};

function Star({ value, draftValue, type, onChange, onChangeDraftValue }: StarProps) {
  const activeValue = draftValue ?? value;

  function onMouseEnter() {
    onChangeDraftValue(type);
  }

  function onClick() {
    onChangeDraftValue(null);
    onChange(type);
  }

  function getButtonBackgroundColor(): string {
    if (activeValue == null || type > activeValue) {
      return "#eee";
    }

    switch (activeValue) {
      case null:
        return "#eee";
      case 1:
        return "#FF3722";
      case 2:
        return "#FF8622";
      case 3:
        return "#FFCE00";
      case 4:
        return "#73CF11";
      case 5:
        return "#00B67A";
    }
  }

  function getSVGFill(): string {
    if (activeValue == null || type > activeValue) {
      return "#ccc";
    }

    switch (activeValue) {
      case null:
        return "#ccc";
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
        return "#fff";
    }
  }

  return (
    <button
      aria-label={getDescription(type)}
      title={getDescription(type)}
      style={{
        display: "inline-block",
        padding: 4,
        borderRadius: 2,
        border: 0,
        cursor: "pointer",
        marginRight: 2,
        backgroundColor: getButtonBackgroundColor(),
        transform: draftValue != null && type <= draftValue ? 'scale(0.95)' : '',
        transition: 'transform 100ms ease-in-out',
      }}
      onMouseEnter={onMouseEnter}
      onClick={onClick}
    >
      <svg
        style={{ display: "block" }}
        xmlns="http://www.w3.org/2000/svg"
        height="24px"
        viewBox="0 0 24 24"
        width="24px"
        fill={getSVGFill()}
      >
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z" />
      </svg>
    </button>
  );
}

type ShopDetailsProps = {
  id?: string,
  name: string,
  description: string,
  websiteURL: string,
  email: string,
  telephone: string,
  imageURL: string,
  state: 'unclaimed' | 'claimed',
  reviews?: Array<{
    id: string,
    body: string,
  }>
}

export default function ShopDetails({
  name,
  websiteURL,
  email,
  telephone,
  imageURL,
  state,
  description,
}: ShopDetailsProps) {
  const [value, setValue] = useState<null | RatingValue>(null);

  return (
    <div className="avenir near-black">
      <div className="pa3 ph5-ns">
        <div className="mw9 center">

          <TopBar />

          {/*<div className="ph3 ph5-ns pv4">
            <Rating value={value} onChange={setValue} />
          </div>*/}

          <div className="mt4 flex">

            <div className="pa1 w-70">
            <img className="w-10" src={imageURL} />
                <Rating value={value} onChange={setValue} />
                <div className="mt2">
                  <div className="lh-copy pa1">I have purchased multiple MacBook Pro's and iPads over time.. Recently I had trouble with my MacBook and took it in to remove the pop ups.. I assumed a simple fix.. They informed me I did have insurance on my product however they did not have the necessary software for the MacBook Pro to complete the task. I would have to pay another $199.00 for that service.. I chose to go to the Apple Store.. Beware if you purchase there warranty because it may not apply to the product purchased.</div>
                </div>
                {/*<Rating value={value} onChange={setValue} />*/}
            </div>

            <div className="pa1 w-30">
              <div className="">
                <MetaSection title={`About ${name}`} use="primary">
                  <p className="ma0 lh-copy">{description}</p>

                  <div className="mt3">
                    <ul className="list pl0 ma0 lh-copy f6">
                      <li className="flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" height="18px" viewBox="0 0 24 24" width="18px" fill="#333"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M6.54 5c.06.89.21 1.76.45 2.59l-1.2 1.2c-.41-1.2-.67-2.47-.76-3.79h1.51m9.86 12.02c.85.24 1.72.39 2.6.45v1.49c-1.32-.09-2.59-.35-3.8-.75l1.2-1.19M7.5 3H4c-.55 0-1 .45-1 1 0 9.39 7.61 17 17 17 .55 0 1-.45 1-1v-3.49c0-.55-.45-1-1-1-1.24 0-2.45-.2-3.57-.57-.1-.04-.21-.05-.31-.05-.26 0-.51.1-.71.29l-2.2 2.2c-2.83-1.45-5.15-3.76-6.59-6.59l2.2-2.2c.28-.28.36-.67.25-1.02C8.7 6.45 8.5 5.25 8.5 4c0-.55-.45-1-1-1z"/></svg>
                        <div className="ml2"><a className="link underline blue" href={`tel:${telephone}`}>{telephone}</a></div>
                      </li>
                      <li className="flex items-center mt1">
                        <svg xmlns="http://www.w3.org/2000/svg" height="18px" viewBox="0 0 24 24" width="18px" fill="#333"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M22 6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6zm-2 0l-8 5-8-5h16zm0 12H4V8l8 5 8-5v10z"/></svg>
                        <div className="ml2"><a className="link underline blue" href={`mailto:${email}`}>{email}</a></div>
                      </li>
                    </ul>
                  </div>
                </MetaSection>
              </div>

              <div className="mt3">
                <MetaSection title="Is this your company?" use="primary">
                  <p className="ma0 lh-copy">Claim your profile to access premium business tools and start getting closer to your customers today!</p>
                  <div className="mt3">
                    <a href="/claim"><button className="bg-green white bn pa2 br2 f6 fw6 w-100 b--dark-blue">Claim this page</button></a>
                  </div>
                </MetaSection>
              </div>

              <div className="mt3">
                <MetaSection title="About uprightly" use="secondary">
                  <ul className="list pl0 ma0 lh-copy">
                    <li className="flex">
                      <div className="mt1 mr3"><svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#111"><g><rect fill="none" height="24" width="24"/></g><g><g><g><path d="M23,8c0,1.1-0.9,2-2,2c-0.18,0-0.35-0.02-0.51-0.07l-3.56,3.55C16.98,13.64,17,13.82,17,14c0,1.1-0.9,2-2,2s-2-0.9-2-2 c0-0.18,0.02-0.36,0.07-0.52l-2.55-2.55C10.36,10.98,10.18,11,10,11s-0.36-0.02-0.52-0.07l-4.55,4.56C4.98,15.65,5,15.82,5,16 c0,1.1-0.9,2-2,2s-2-0.9-2-2s0.9-2,2-2c0.18,0,0.35,0.02,0.51,0.07l4.56-4.55C8.02,9.36,8,9.18,8,9c0-1.1,0.9-2,2-2s2,0.9,2,2 c0,0.18-0.02,0.36-0.07,0.52l2.55,2.55C14.64,12.02,14.82,12,15,12s0.36,0.02,0.52,0.07l3.55-3.56C19.02,8.35,19,8.18,19,8 c0-1.1,0.9-2,2-2S23,6.9,23,8z"/></g></g></g></svg></div>
                      <div>Reviews are published instantly. No moderation, no delays.</div>
                    </li>
                    <li className="flex mt2">
                      <div className="mt1 mr3"><svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#111"><path d="M0 0h24v24H0z" fill="none"/><path d="M10 18h4v-2h-4v2zM3 6v2h18V6H3zm3 7h12v-2H6v2z"/></svg></div>
                      <div>You can filter by star rating, customer experience, and keywords.</div>
                    </li>
                    <li className="flex mt2">
                      <div className="mt1 mr3"><svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#111"><g><rect fill="none" height="24" width="24"/></g><g><g/><g><path d="M21,5l-9-4L3,5v6c0,5.55,3.84,10.74,9,12c2.3-0.56,4.33-1.9,5.88-3.71l-3.12-3.12c-1.94,1.29-4.58,1.07-6.29-0.64 c-1.95-1.95-1.95-5.12,0-7.07c1.95-1.95,5.12-1.95,7.07,0c1.71,1.71,1.92,4.35,0.64,6.29l2.9,2.9C20.29,15.69,21,13.38,21,11V5z"/><circle cx="12" cy="12" r="3"/></g></g></svg></div>
                      <div>We work around the clock to safeguard our platform and combat fake reviews.</div>
                    </li>
                  </ul>
                </MetaSection>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}

type MetaSectionProps = {
  title: string,
  children: JSX.Element | JSX.Element[],
  use: 'primary' | 'secondary'
}

function MetaSection({title, children, use}: MetaSectionProps) {
  let className = "br2 pa3 ba b--dashed near-black"

  switch (use) {
    case 'primary':
      className += ' b--moon-gray'
      break;
    case 'secondary':
      className += ' b--transparent bg-near-white'
      break;
  }

  return (
    <div className={className}>
      <h4 className="ma0 fw6">{title}</h4>

      <div className="mt3">
        {children}
      </div>
    </div>
  )
}




            /*<ul className="list pl0 ma0 mt3">
              <li>Claimed their Trustpilot profile: December 2020.</li>
              <li>Claimed their Trustpilot profile: December 2020.</li>
            </ul>*
            *<Rating value={value} onChange={setValue} />*/