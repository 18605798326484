import React from "react";
import { useState, useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import ShopDetails from './ShopDetails'
import SignUp from './SignUp'

type LoadObject<V> =
  | { state: "loading" }
  | { state: "error" }
  | { state: "loaded", value: V };

type Observation = {
  date: string,
  value: string,
};

type Observations = {
  observations: Array<Observation>,
};

function useFetch<Value>(url: string): LoadObject<Value> {
  const [state, setState] = useState<LoadObject<Value>>({ state: "loading" });
  useEffect(() => {
    let isCanceled = false;

    fetch(url)
      .then((response) => response.json())
      .then((value) => {
        if (!isCanceled) {
          setState({
            state: "loaded",
            value,
          });
        }
      })
      .catch(() => {
        if (!isCanceled) {
          setState({
            state: "error",
          });
        }
      });
    return () => {
      isCanceled = true;
    };
  }, [url]);
  return state;
}

type Point = {
  x: number,
  y: number
}

type LineProps = {
  color: string,
  points: Array<Point>
  width: number,
  height: number,
  padding: number,
}

const line = (pointA: Point, pointB: Point) => {
  const lengthX = pointB.x - pointA.x
  const lengthY = pointB.y - pointA.y
  return {
    length: Math.sqrt(Math.pow(lengthX, 2) + Math.pow(lengthY, 2)),
    angle: Math.atan2(lengthY, lengthX)
  }
}

const controlPoint = (current: Point, previous: Point, next: Point, reverse: boolean): Point => {
  // When 'current' is the first or last point of the array
  // 'previous' or 'next' don't exist.
  // Replace with 'current'
  const p = previous || current
  const n = next || current
  // The smoothing ratio
  const smoothing = 0.2
  // Properties of the opposed-line
  const o = line(p, n)
  // If is end-control-point, add PI to the angle to go backward
  const angle = o.angle + (reverse ? Math.PI : 0)
  const length = o.length * smoothing
  // The control point position is relative to the current point
  const x = current.x + Math.cos(angle) * length
  const y = current.y + Math.sin(angle) * length
  return {x, y}
}

const bezierCommand = (point: Point, i: number, a: Array<Point>) => {
  // start control point
  const {x: cpsX, y: cpsY} = controlPoint(a[i - 1], a[i - 2], point, false)
  // end control point
  const {x: cpeX, y: cpeY} = controlPoint(point, a[i - 1], a[i + 1], true)
  return `C ${cpsX},${cpsY} ${cpeX},${cpeY} ${point.x},${point.y}`
}

function Line({color, points, width, height, padding}: LineProps) {
  return (
    <>
    <path
      fill="none"
      className="draw2"
      stroke={color}
      strokeWidth="2"
      strokeLinejoin="round"
      strokeLinecap="round"
      d={
        points.map((point, i) => i === 0 ? `M ${point.x.toFixed(2)} ${point.y.toFixed(2)}` : `L ${point.x.toFixed(2)} ${point.y.toFixed(2)}`).join(",")
       }
    ></path>
    <polygon fill={color} opacity={0.1} points={
      [...points, {x: width - padding, y: height - padding}].map((point, i) => `${point.x.toFixed(2)},${point.y.toFixed(2)}`).join(" ")
    } />
    </>
  );
}


//    d={
//  points.map((point, i) => i === 0 ? `M ${point.x.toFixed(2)} ${point.y.toFixed(2)}` : bezierCommand(point, i, points)).join(",")
// }


/*    {
      points.map((point, i) => 
        <Point key={i} point={point} />
      )
    }
*/


// function Point({point}: {point: Point}) {
//   const [state, setState] = useState({isMouseOver: false})
//   return <circle cx={point.x} cy={point.y} r={12} fill={state.isMouseOver ? 'red' : 'transparent'} onMouseEnter={() => setState({isMouseOver: true})}  onMouseLeave={() => setState({isMouseOver: false})}  />;
// }

type GridProps = {
  width: number,
  height: number,
  padding: number,
  color: string,
  startTime: number,
  endTime: number,
  minValue: number,
  maxValue: number,
}

function Grid({color, width, height, padding, startTime, endTime, minValue, maxValue}: GridProps) {
  const total = endTime - startTime;

  const yAxisMarkers: Array<{label: string, y: number}> = [];
  for (let x = 0; x < maxValue; x += 5) {
    const marker = {
      label: String(x),
      y: height - x * (width - 2 * padding),
    }
    yAxisMarkers.push(marker);
  }

  const xAxisMarkers: Array<{label: string, x: number}> = [];
  for (let year = (new Date(startTime)).getFullYear(); year < (new Date(endTime)).getFullYear(); year++) {
    if (year % 10 > 0) {
      continue;
    }
    const time = (new Date(year, 0, 1)).getTime();
    if (time < startTime || time > endTime) {
      continue;
    }
    const marker = {
      label: '' + year,
      x: ((time - startTime) / (endTime - startTime)) * (width - 2 * padding),
    }
    xAxisMarkers.push(marker);
  }

  return <>
    <line x1={padding} y1={height - padding} x2={width - padding} y2={height - padding} stroke={color} />
    {xAxisMarkers.map(({label, x}, i) => 
      <g key={i} transform={`translate(${padding + x},0)`}>
        <line y2={height - padding} stroke="#222" />
        <g textAnchor="middle" transform={`translate(0,${height - padding})`}>
          <line y2={4} stroke={color} />
          <text y={16} fill={color} style={{fontSize: 10}}>{label}</text>
         </g>
      </g>
      )}
  </>
}

type Data = Array<{
}>

function App() {
  const seriesID = "CPIAUCSL";
  const observations: LoadObject<Observations> =
    useFetch<Observations>(`/data/${seriesID}-observations.json`);

  if (observations.state !== "loaded") {
    return null;
  }

  const value = observations.value;

  const minY = value.observations.reduce(
    (prevValue: null | number, {value}) => (prevValue == null || Number(value) < prevValue) ? Number(value) : prevValue,
    null,
  ) ?? 0;
  const maxY = value.observations.reduce(
    (prevValue: null | number, {value}) => (prevValue == null || Number(value) > prevValue) ? Number(value) : prevValue,
    null,
  ) ?? 0;
  const scaleY = maxY - minY

  const startTime = (new Date(value.observations[0].date)).getTime();
  const endTime = (new Date(value.observations[value.observations.length - 1].date)).getTime();
  const scaleX = endTime - startTime;

  const width = 850;
  const height = 300;
  const padding = 20;

  const points = value.observations
    .map(
      (observation, i) => ({
        x: padding + ((new Date(observation.date).getTime() - startTime) / scaleX) *
          (width - 2 * padding),
        y: (height - padding) - ((Number(observation.value) - minY) / scaleY) * (height - 2 * padding),
      })
    );

  switch (window.location.pathname) {
    case '/claim':
      return <SignUp />
    case '/':
    default:
      return (
        <ShopDetails 
          name={"Best Buy"}
          description={"Shop Best Buy for electronics, computers, appliances, cell phones, video games & more new tech. In-store pickup & free 2-day shipping on thousands of items."}
          websiteURL={"http://bestbuy.com"}
          email={"onlinestore@bestbuy.com"}
          telephone={"+1 888-237-8289"}
          imageURL={"https://upload.wikimedia.org/wikipedia/commons/c/c4/Best_Buy_logo_2018.svg"}
          state="unclaimed"
        />
      );
  }

  // return (
  //   <ShopDetails 
  //     name={"Best Buy"}
  //     description={"Shop Best Buy for electronics, computers, appliances, cell phones, video games & more new tech. In-store pickup & free 2-day shipping on thousands of items."}
  //     websiteURL={"http://bestbuy.com"}
  //     email={"onlinestore@bestbuy.com"}
  //     telephone={"+1 888-237-8289"}
  //     imageURL={"https://upload.wikimedia.org/wikipedia/commons/c/c4/Best_Buy_logo_2018.svg"}
  //     state="unclaimed"
  //   />
  //   // ec-bg-dark 
  //   // <div className="code white flex justify-center pa3 min-vh-100 pa1">
  //   //   <div className="w-100 mw8">
  //   //     <div className="mt8">
  //   //       <svg width={width} height={height}>
  //   //         <Grid color="#444" width={width} height={height} padding={padding} startTime={startTime} endTime={endTime} minValue={minY} maxValue={maxY} />
  //   //         <Line color="#357EDD" points={points} width={width} height={height} padding={padding} />
  //   //       </svg>
  //   //     </div>

        
  //   //   </div>
  //   // </div>
  // );
  //         // <circle cx={points[points.length - 1].x} cy={points[points.length - 1].y} r={2} fill="red" />

  // <Grid color="#444" width={width} height={height} padding={padding} />
}

type IconProps = {
  type: 1 | 2 | 3 | 4 | 5
}

function Icon({type}: IconProps) {
  switch (type) {
case 5:
return <><path d="M0 0h24v24H0V0z" fill="none"/><circle cx="15.5" cy="9.5" r="1.5"/><circle cx="8.5" cy="9.5" r="1.5"/><path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm-5-6c.78 2.34 2.72 4 5 4s4.22-1.66 5-4H7z"/></>
case 4:
return <><path d="M0 0h24v24H0V0z" fill="none"/><circle cx="15.5" cy="9.5" r="1.5"/><circle cx="8.5" cy="9.5" r="1.5"/><path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm0-4c-.73 0-1.38-.18-1.96-.52-.12.14-.86.98-1.01 1.15.86.55 1.87.87 2.97.87 1.11 0 2.12-.33 2.98-.88-.97-1.09-.01-.02-1.01-1.15-.59.35-1.24.53-1.97.53z"/></>
case 3:
return <><path d="M0 0h24v24H0V0z" fill="none"/><path d="M9 15.5h6v1H9v-1z"/><circle cx="15.5" cy="9.5" r="1.5"/><circle cx="8.5" cy="9.5" r="1.5"/><path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"/></>
case 2:
return <><path d="M0 0h24v24H0V0z" fill="none"/><circle cx="15.5" cy="9.5" r="1.5"/><circle cx="8.5" cy="9.5" r="1.5"/><path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm0-3.5c.73 0 1.39.19 1.97.53.12-.14.86-.98 1.01-1.14-.85-.56-1.87-.89-2.98-.89-1.11 0-2.13.33-2.99.88.97 1.09.01.02 1.01 1.14.59-.33 1.25-.52 1.98-.52z"/></>
case 1:
return <><path d="M0 0h24v24H0V0z" fill="none"/><circle cx="15.5" cy="9.5" r="1.5"/><circle cx="8.5" cy="9.5" r="1.5"/><path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm0-6c-2.33 0-4.32 1.45-5.12 3.5h1.67c.69-1.19 1.97-2 3.45-2s2.75.81 3.45 2h1.67c-.8-2.05-2.79-3.5-5.12-3.5z"/></>
  }
}

type EcoChartProps = {
  height: number,
  width: number,
}

export default App;
